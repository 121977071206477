import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SAVE_BLOCKS_AND_STEALS = true;

const AdminGames = () => {
  const { gameID } = useParams();
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({})

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser)

        if (utils.isAdminEmail(currentUser.email)) {
          getLeague()
        } else {
          window.location.href = "/";
        }
      } else {
        setCurrentUser(null)
        window.location.href = "/login";
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])



  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    if (gameID) {
      let this_game = utils.getGameForID(league.games, gameID)
      let teams = league.seasonTeams.filter((team) => team.season == this_game.season)
      let updatedTeams = []
      for (const t of teams) {
        let teamData = league.teams.find((tm) => tm.id == t.team_id)
        let team = { ...teamData, ...t }

        updatedTeams.push(team)
      }
      league.teams = updatedTeams
    }

    setLeague(league);
    setDataReady(true);
  }

  function displayFinishedGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let away_team = utils.getTeamForID(league.teams, game.away_team_id);
    let home_team = utils.getTeamForID(league.teams, game.home_team_id);

    if (game.status === 'completed') {
      return (
        <div className="league-game">
          <div className='league-game-info'>
            <p className="league-title-standard-small" key={game.id + "-date"}>{ game.date.toDateString() }</p>
            { game.away_team_id === game.winner && <p key={game.id}><b>{ away_team.name.toUpperCase() } { game.score.away }</b> - { game.score.home } { home_team.name.toUpperCase() }</p> }
            { game.home_team_id === game.winner && <p key={game.id}>{ away_team.name.toUpperCase() } { game.score.away } - <b>{ game.score.home } { home_team.name.toUpperCase() }</b></p> }
          </div>
          <div className='league-game-edit-button'>
            <a type="button" className="btn btn-dark" href={'/admin/games/' + game.id}>Edit</a>
          </div>
        </div>
      )
    } else {
      return (
        <div className="league-game">
          <div className='league-game-info'>
            <p className="league-title-standard-small" key={game.id + "-date"}>{ game.date.toDateString() }</p>
            <p key={game.id}>Score Pending - { away_team.name.toUpperCase() } vs. { home_team.name.toUpperCase() }</p>
          </div>
          <div className='league-game-edit-button'>
            <a type="button" className="btn btn-dark" href={'/admin/games/' + game.id}>Edit</a>
          </div>
        </div>
      )
    }
  }

  function displayGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let away_team = utils.getTeamForID(league.teams, game.away_team_id);
    let home_team = utils.getTeamForID(league.teams, game.home_team_id);


    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }


    return (
      <div className="league-game" key={game.id + '-game'}>
        <div className='league-game-info'>
          <p className="league-title-skinny-small" key={game.id + "-date"}>{ game.date.toDateString() }</p>
          <p key={game.id}>{ away_team.name.toUpperCase() } vs. { home_team.name.toUpperCase() }</p>
        </div>
        <div className='league-game-edit-button'>
          <a type="button" className="btn btn-dark" href={'/admin/games/' + game.id}>Edit</a>
        </div>
      </div>
    )
  }

  function displayGames () {
    const today = Date.now();

    const futureGames = league.games.filter((game) => game.season == SEASON && game.status !== 'completed' && game.date > today && (game.away_team_id !== '' && game.home_team_id !== ''))
    const pastGames = league.games.filter((game) => game.season == SEASON && (game.date <= today || game.status === 'completed') )

    const sortedPastGames = pastGames.sort((a, b) => (a.date < b.date) ? -1 : 1)
    const sortedFutureGames = futureGames.sort((a, b) => (a.date < b.date) ? -1 : 1)

    const pastGameElements = sortedPastGames.map((game, place) => displayFinishedGameThisWeek(game) )
    const futureGameElements = sortedFutureGames.map((game, place) => displayGameThisWeek(game) )
    return (
      <>
        { pastGameElements }
        { futureGameElements }
      </>
    );
  }

  function getGameFromGameID () {
    return getGameForGameID(gameID);
  }

  function getGameForGameID (id) {
    return league.games.find((game) => id === game.id);
  }

  function displayJerseyNumber (player) {
    if (player.jersey_number == '') {
      return ``
    }
    return `#${player.jersey_number}`
  }

  function displayPlayerName (player) {
    return `${player.first_name} ${player.last_name}`
  }

  function displayPlayerInputRowsForTeam(game, player) {
    let playerStat = league.gameStats.find((stat) => stat.game_id == game.id && stat.player_id == player.player_id)

    if (!playerStat || ['dnp', 'forfeit', 'no-stats'].includes(playerStat.status)) {
      playerStat = {
        points: '',
        rebounds: '',
        assists: '',
        blocks: '',
        steals: ''
      }
    }

    return (
      <>
        <tr>
          <th className="league-table-data-name">{ displayJerseyNumber(player) } { displayPlayerName(player) }</th>
          <th className="league-table-data-name full-input">
            <input id={ getIDForPlayerAndStat(player, 'points') } type="number" className="form-control" defaultValue={ playerStat.points }></input>
          </th>
          <th className="league-table-data-name full-input">
            <input id={ getIDForPlayerAndStat(player, 'rebounds') } type="number" className="form-control" defaultValue={ playerStat.rebounds }></input>
          </th>
          <th className="league-table-data-name full-input">
            <input id={ getIDForPlayerAndStat(player, 'assists') } type="number" className="form-control" defaultValue={ playerStat.assists }></input>
          </th>
          { SAVE_BLOCKS_AND_STEALS == false ? '' :
            <>
            <th className="league-table-data-name full-input">
              <input id={ getIDForPlayerAndStat(player, 'blocks') } type="number" className="form-control" defaultValue={ playerStat.blocks }></input>
            </th>
            <th className="league-table-data-name full-input">
              <input id={ getIDForPlayerAndStat(player, 'steals') } type="number" className="form-control" defaultValue={ playerStat.steals }></input>
            </th>
            </>
          }
        </tr>
      </>
    )
  }

  function displayPlayerInputRows(game, away_team, home_team) {
    let awayTeamPlayers = away_team.players.map((id) => getPlayerByID(id)).sort((a, b) => jerseyNumberSort(a,b))
    let awayTeamPlayerRows = awayTeamPlayers.map((player, place) => displayPlayerInputRowsForTeam(game, player))

    let homeTeamPlayers = home_team.players.map((id) => getPlayerByID(id)).sort((a, b) => jerseyNumberSort(a,b))
    let homeTeamPlayerRows = homeTeamPlayers.map((player, place) => displayPlayerInputRowsForTeam(game, player))

    return (
      <>
        <tr>
          <th className="league-table-heading-name" colSpan="100%"><span>{ away_team.name.toUpperCase() }</span></th>
        </tr>
        { awayTeamPlayerRows }
        <tr>
          <th className="league-table-heading-name" colSpan="100%"><span>{ home_team.name.toUpperCase() }</span></th>
        </tr>
        { homeTeamPlayerRows }
      </>
    )
  }

  function jerseyNumberSort (a, b) {
    if (a.jersey_number == '' && b.jersey_number == '') {
      return 0;
    } else if (a.jersey_number == '') {
      return 1;
    } else if (b.jersey_number == '') {
      return -1;
    } else {
      let number1 = parseInt(a.jersey_number)
      let number2 = parseInt(b.jersey_number)

      return number1 < number2 ? -1 : 1
    }
  }

  function eraseGameData () {
    // no op
    return;

    let erasedGame = getGameFromGameID();
    let archivedGame = {
      'date': Date.now(),
      'game': Object.assign({}, JSON.parse(JSON.stringify(erasedGame)))
    }
    if (erasedGame.archivedGame) {
      erasedGame.archivedGame.push(archivedGame);
    } else {
      erasedGame.archivedGame = [archivedGame];
    }

    erasedGame.status = 'scheduled';
    erasedGame.winner = '';
    erasedGame.score.away = 0;
    erasedGame.score.home = 0;

    let awayFinalScore = document.getElementById('finalScoreAwayTeam');
    let homeFinalScore = document.getElementById('finalScoreHomeTeam');
    awayFinalScore.value = '';
    homeFinalScore.value = '';

    let gamePlayers = league.players.filter((player) => player.team_id === erasedGame.away_team || player.team_id === erasedGame.home_team_id)
    gamePlayers.forEach((player) => {
      let points = document.getElementById(getIDForPlayerAndStat(player, 'points'));
      let rebounds = document.getElementById(getIDForPlayerAndStat(player, 'rebounds'));
      let assists = document.getElementById(getIDForPlayerAndStat(player, 'assists'));
      points.value = '';
      rebounds.value = '';
      assists.value = '';
    })

    let updatedGames = league.games.map((game) => game.id === gameID ? erasedGame : game)
    league.games = updatedGames;

    saveLeague();
  }

  function saveGameData () {
    let game = getGameFromGameID();
    let away_team = utils.getTeamForID(league.teams, game.away_team_id)
    let home_team = utils.getTeamForID(league.teams, game.home_team_id)

    let awayFinalScore = parseInt(document.getElementById('finalScoreAwayTeam').value);
    let homeFinalScore = parseInt(document.getElementById('finalScoreHomeTeam').value);
    let noStatsCheckbox = document.getElementById('noStatsCheckbox').checked;
    let forfeitCheckbox = document.getElementById('forfeitCheckbox').checked;

    game.score.away = awayFinalScore;
    game.score.home = homeFinalScore;
    game.winner = awayFinalScore > homeFinalScore ? away_team.id : home_team.id;
    game.status = 'completed';

    let gameStats = [];

    let gamePlayerIDs = [...away_team.players, ...home_team.players]
    let gamePlayers = gamePlayerIDs.map((id) => getPlayerByID(id))
    gamePlayers.forEach((player) => {
      let playerTeam = utils.getCurrentTeamForPlayerID(league.teams, player.player_id)
      let points = parseInt(document.getElementById(getIDForPlayerAndStat(player, 'points')).value);
      let rebounds = parseInt(document.getElementById(getIDForPlayerAndStat(player, 'rebounds')).value);
      let assists = parseInt(document.getElementById(getIDForPlayerAndStat(player, 'assists')).value);
      let blocks;
      let steals;
      if (SAVE_BLOCKS_AND_STEALS) {
        blocks = parseInt(document.getElementById(getIDForPlayerAndStat(player, 'blocks')).value);
        steals = parseInt(document.getElementById(getIDForPlayerAndStat(player, 'steals')).value);
      }

      let gameStat = {
        game_id: game.id,
        player_id: player.player_id,
        team_id: playerTeam.id
      }
      if (SAVE_BLOCKS_AND_STEALS) {
        if (isNaN(points) && isNaN(rebounds) && isNaN(assists) && isNaN(blocks) && isNaN(steals)) {
          gameStat.points = 0
          gameStat.rebounds = 0
          gameStat.assists = 0
          gameStat.blocks = 0
          gameStat.steals = 0
          if (forfeitCheckbox) {
            gameStat.status = 'forfeit'
          } else if (noStatsCheckbox) {
            gameStat.status = 'no-stats'
          } else {
            gameStat.status = 'dnp'
          }
        } else {
          points = points || 0
          rebounds = rebounds || 0
          assists = assists || 0
          blocks = blocks || 0
          steals = steals || 0

          gameStat.points = points
          gameStat.rebounds = rebounds
          gameStat.assists = assists
          gameStat.blocks = blocks
          gameStat.steals = steals
          gameStat.status = 'played'
        }
      } else {
        if (isNaN(points) && isNaN(rebounds) && isNaN(assists)) {
          gameStat.points = 0
          gameStat.rebounds = 0
          gameStat.assists = 0
          if (forfeitCheckbox) {
            gameStat.status = 'forfeit'
          } else if (noStatsCheckbox) {
            gameStat.status = 'no-stats'
          } else {
            gameStat.status = 'dnp'
          }
        } else {
          points = points || 0
          rebounds = rebounds || 0
          assists = assists || 0

          gameStat.points = points
          gameStat.rebounds = rebounds
          gameStat.assists = assists
          gameStat.status = 'played'
        }
      }
      gameStats.push(gameStat)
    })

    let updatedGames = league.games.map((g) => g.id === gameID ? game : g)

    league.games = updatedGames;

    league.gameStats = league.gameStats.filter((stat) => stat.game_id !== gameID)
    league.gameStats = league.gameStats.concat(gameStats)

    // const playoffSeason2AdvancementMap = {
    //   'lyx91829fl0og4o642c': 'lyx91829tsr9gob41xg', // first round --> quarterfinal 1
    //   'lyx91829c57wku9n3mt': 'lyx91829tsr9gob41xg', // first round --> quarterfinal 1
    //   'lyx91829bfhklolam09': 'lyx91829kkrzg9i76tg', // first round --> quarterfinal 2
    //   'lyx91829fm37i3t4ess': 'lyx91829kkrzg9i76tg', // first round --> quarterfinal 2
    //   'lyx91829tsr9gob41xg': 'lyx91829vsmvgduvav',  // quarterfinal 1 --> semifinal 1
    //   'lyx91829kkrzg9i76tg': 'lyx91829zfemfy7504',  // quarterfinal 2 --> semifinal 2
    //   'lyx91829vsmvgduvav': 'lyx91829spuoz8eie8',   // semifinal 1 --> championship
    //   'lyx91829zfemfy7504': 'lyx91829spuoz8eie8'    // semifinal 2 --> championship
    // }

    // if it's a playoff game, advance team to next game
    if (game.type === 'playoff') {
      console.log(game)
      let gameWinnerID = `winner_${game.id}`

      let nextGame = league.games.find((game) => game.type == 'playoff' && game.playoff_tags.includes(gameWinnerID))
      if (nextGame) {
        const winners = nextGame.playoff_tags.filter((tag) => tag.startsWith('winner_'))
        if (winners.length === 1) { // set to home team for game
          nextGame.home_team_id = game.winner;
        } else if (winners.length === 2){
          if (winners[0] === gameWinnerID) { // set to away team for game
            nextGame.away_team_id = game.winner;
          } else { // set to home team for game
            nextGame.home_team_id = game.winner;
          }
        }
      }
    }

    // New Save Methods
    // saveGameStats();
    saveGame(game);
    saveSeasonTeams();
    saveSeasonLeaders();
    saveSeasonUpcomingGames();

    // Current Save Method
    // Save entire league JSON with updated data for game
    saveLeague();
  }

  const saveSeasonUpcomingGames = async ()=> {
    console.log('saving season upcoming games')
    try {
      const seasonRef = doc(db, 'seasons', SEASON);
      const upcomingGames = utils.getUpcomingGames(league, SEASON)

      await updateDoc(seasonRef, {
        upcomingGames: upcomingGames
      });
    } catch (error) {
      console.error('Error saving season upcoming games:', error);
    }
  }

  const saveSeasonTeams = async ()=> {
    console.log('saving season teams')
    try {
      const seasonRef = doc(db, 'seasons', SEASON);
      const teams = utils.getSeasonTeams(league, SEASON)

      await updateDoc(seasonRef, {
        teams: teams
      });
    } catch (error) {
      console.error('Error saving season teams:', error);
    }
  }

  const saveSeasonLeaders = async () => {
    console.log('saving season leaders')
    try {
      const seasonRef = doc(db, 'seasons', SEASON);

      const leaderCount = 10;
      const leaders = utils.getSeasonLeaders(league, SEASON, leaderCount)

      await updateDoc(seasonRef, {
        leaders: leaders
      });
    } catch (error) {
      console.error('Error saving season leaders:', error);
    }
  }

  const saveGame = async (game) => {
    try {
      const gameRef = doc(db, 'games', game.id);

      await updateDoc(gameRef, {
        score: {
          home: game.score.home,
          away: game.score.away
        },
        winner: game.winner,
        status: game.status
      });
    } catch (error) {
      console.error('Error saving season leaders:', error);
    }
  }

  // const saveGameStats = async () => {
  //   console.log('saving game')
  //   try {
  //     const gameStatRef = doc(db, 'gameStats', gameID);

  //     let thisGameStats = league.gameStats.filter((stat) => stat.game_id === gameID)

  //     thisGameStats.map((stat) => {
  //       delete stat.game_id
  //       stat['playerID'] = stat.player_id
  //       delete stat.player_id
  //       stat['teamID'] = stat.team_id
  //       delete stat.team_id
  //     })

  //     console.log(thisGameStats)

  //     await updateDoc(gameStatRef, { stats: thisGameStats });
  //     console.log('Game saved successfully!');
  //   } catch (error) {
  //     console.error('Error saving game:', error);
  //   }
  // }

  // const saveSeasonData = async () => {
  //   console.log('saving season data')
  // }

  const saveLeague = async () => {
    console.log('saving league')
    await setDoc(doc(db, "league", DATABASE_SOURCE), league);
    console.log('redirect')
    window.location.href = `/games/${gameID}`
  }

  function getIDForPlayerAndStat(player, stat) {
    return `${player.player_id}-${stat}`
  }

  function getPlayerByID (id) {
    let player = league.players.find((p) => p.player_id == id);

    if (player) {
      return player
    } else {
      player = {
        jersey_number: '',
        player_id: '0'
      }
      player['player_id'] = id
      player['first_name'] = id
      player['last_name'] = ''

      return player
    }
  }

  function noStatsGame () {
    let statsForThisGame = league.gameStats.filter((stat) => stat.game_id == gameID);

    let statStatuses = statsForThisGame.map((stat) => stat.status)

    return statStatuses.includes('no-stats');
  }

  function forfeitedGame () {
    let statsForThisGame = league.gameStats.filter((stat) => stat.game_id == gameID);

    let statStatuses = statsForThisGame.map((stat) => stat.status)

    return statStatuses.includes('forfeit');
  }

  function displayEditGame () {
    let game = getGameFromGameID();

    if (game.date < DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED) {
      SAVE_BLOCKS_AND_STEALS = false;
    }
    let away_team = utils.getTeamForID(league.teams, game.away_team_id);
    let home_team = utils.getTeamForID(league.teams, game.home_team_id);
    return (
      <>
        <div>
            <form className='league-game-edit'>
              <div className='text-left admin-edit-col'>
                <p><b>{ away_team.name.toUpperCase() }</b></p>
                <div className="mb-3">
                  <label className="form-label">Final Score</label>
                  <input type="number" className="form-control" id="finalScoreAwayTeam" defaultValue={ game.status == 'completed' ? game.score.away : '' }></input>
                </div>
              </div>

              <div className='mb-3 admin-edit-col centered-edit-col'>
                <label className="form-check-label">
                  No Stats?
                </label>
                <input className="form-check-input big-checkbox" type="checkbox" value="" id="noStatsCheckbox" defaultChecked={ noStatsGame() }/>
              </div>

              <div className='mb-3 admin-edit-col centered-edit-col'>
                <label className="form-check-label">
                  Forfeit?
                </label>
                <input className="form-check-input big-checkbox" type="checkbox" value="" id="forfeitCheckbox" defaultChecked={ forfeitedGame() }/>
              </div>

              <div className='text-right admin-edit-col'>
                <p><b>{ home_team.name.toUpperCase() }</b></p>
                <div className="mb-3">
                  <label className="form-label">Final Score</label>
                  <input type="number" className="form-control" id="finalScoreHomeTeam" defaultValue={ game.status == 'completed' ? game.score.home : '' }></input>
                </div>
              </div>
            </form>

            <div className="league-table-container">
              <table className="table league-table">
              <thead className="league-table-heading-hole">
                  <tr>
                    <th className="league-table-heading-name"><span>Player</span></th>
                    <th className="league-table-heading hole"><span>PTS</span></th>
                    <th className="league-table-heading hole"><span>REB</span></th>
                    <th className="league-table-heading hole"><span>AST</span></th>
                    { SAVE_BLOCKS_AND_STEALS == false ? '' :
                      <>
                      <th className="league-table-heading hole"><span>BLK</span></th>
                      <th className="league-table-heading hole"><span>STL</span></th>
                      </>
                    }
                  </tr>
                </thead>
                <thead className="league-table-heading-total">
                <tr>
                    <th className="league-table-heading-name"><span>Player</span></th>
                    <th className="league-table-heading hole"><span>pts</span></th>
                    <th className="league-table-heading hole"><span>reb</span></th>
                    <th className="league-table-heading hole"><span>ast</span></th>
                    { SAVE_BLOCKS_AND_STEALS == false ? '' :
                      <>
                      <th className="league-table-heading hole"><span>blk</span></th>
                      <th className="league-table-heading hole"><span>stl</span></th>
                      </>
                    }
                  </tr>
                </thead>
                <tbody>
                  { displayPlayerInputRows(game, away_team, home_team) }
                </tbody>
              </table>
            </div>
          </div>
        <div className='league-game-save-button text-align-center'>
          <button type="button" className="btn btn-dark" onClick={saveGameData}>Save</button>
        </div>
        <div className='league-game-erase-button text-align-center'>
          <button type="button" className="btn btn-danger" onClick={eraseGameData}>Erase</button>
        </div>
      </>
    )
  }

  if (dataReady == false || currentUser == null) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <p className='league-admin-back-button'><a className='league-link' href="/admin"><b>← Back to Admin Home</b></a></p>
            { gameID ? <p className='league-admin-back-button'><a className='league-link' href="/admin/games"><b>← Back to Admin &gt; Games</b></a></p> : '' }

            <p className="league-title-standard-secondary text-align-center">Edit Game</p>
            <div className="dropdown league-dropdown">
            </div>
            <div className="league-table-container schedule">
              {
                gameID ?
                displayEditGame()
                :
                displayGames()
              }
            </div>
          </div>

        </div>

        <Footer/>
      </div>
    );
  }
}

export default AdminGames;
