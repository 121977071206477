import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, addDoc, updateDoc, doc, Timestamp, collectionGroup } from "firebase/firestore";
import { db } from '../firebase.js';

const PAST_SEASON = utils.getCurrentOrMostRecentSeason();
const SEASON_TO_ADD_PLAYER_TO = utils.getSeasonToAddPlayerTo();
const SEASON_START_DATE = utils.getUpcomingSeasonStartDate();

const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SHOW_BLOCKS_AND_STEALS = true;

const Records = () => {
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    setLeague(league);
    setDataReady(true);
  }

  function showTotalStatRow(player, stat) {
    let statLabelAbbv = {
      'assists': 'AST',
      'points': 'PTS',
      'rebounds': 'REB',
      'blocks': 'BLK',
      'steals': 'STL'
    }
    let avgStatLabelAbbv = {
      'assists': 'APG',
      'points': 'PPG',
      'rebounds': 'RPG',
      'blocks': 'BPG',
      'steals': 'SPG'
    }
    let p = utils.getPlayerByID(league.players, player.player_id)
    return (
      <div className='league-game-player-stat-group'>
        <p className='league-game-player-name' key={player.player_id + '-player-name'}><span className='league-total-stat-place'>{ player.place }</span> <a className="unstyled-link" href={"/players/" + player.player_id }>{ utils.displayPlayerName(p) }</a></p>
        <p className='league-game-player-stat' key={player.player_id + '-player-stat'}>{ player.total } <span className='league-season-average-label'>{ statLabelAbbv[stat] }</span> <span className='league-season-average-label'>({ parseFloat(player.average.toFixed(1)) } { avgStatLabelAbbv[stat] })</span></p>
      </div>
    )
  }

  function getStatsForPlayer (player_id, withStealsAndBlocks) {
    let allStatsForPlayer;
    if (withStealsAndBlocks) {
      allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && utils.getGameForID(league.games, gameStat.game_id).date > DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED && gameStat.status == 'played')
    } else {
      allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && gameStat.status == 'played')
    }

    return allStatsForPlayer;
  }

  function showTopStats (stat) {
    let playerAverages = []
    let playerTotals = []

    league.players.forEach((p) => {
      let allStatsForPlayer;
      if (stat == 'blocks' || stat == 'steals') {
        allStatsForPlayer = getStatsForPlayer(p.player_id, true);
      } else {
        allStatsForPlayer = getStatsForPlayer(p.player_id, false);
      }

      if (allStatsForPlayer.length == 0) {
        return;
      }

      let total = allStatsForPlayer.reduce(function (tot, player, _) {
        return tot + player[stat];
      }, 0);

      let average = allStatsForPlayer.reduce(function (avg, player, _, { length }) {
        return avg + player[stat] / length;
      }, 0);

      let playerTotal = {
        'player_id': p.player_id,
        'name': utils.displayPlayerName(p),
        'total': total,
        'average': average,
        'games': allStatsForPlayer.length
      }
      playerTotals.push(playerTotal)
    })

    let topThreePlayerTotals = playerTotals.sort((function(a,b){
      return a.total > b.total ? -1 : 1;
    })).slice(0, 3);

    let topFivePlayerTotals = playerTotals.sort((function(a,b){
      return a.total > b.total ? -1 : 1;
    })).slice(0, 5);

    let topTenPlayerTotals = playerTotals.sort((function(a,b){
      return a.total > b.total ? -1 : 1;
    })).slice(0, 10);

    let topTwentyPlayerTotals = playerTotals.sort((function(a,b){
      return a.total > b.total ? -1 : 1;
    })).slice(0, 20);

    let statLabel = {
      'assists': 'Assists',
      'points': 'Points',
      'rebounds': 'Rebounds',
      'blocks': 'Blocks',
      'steals': 'Steals'
    }

    // Choose between top 3 or top 5 players to show
    // let topPlayerTotals = topThreePlayerTotals;
    // let topPlayerTotals = topFivePlayerTotals;
    // let topPlayerTotals = topTenPlayerTotals;
    let topPlayerTotals = topTwentyPlayerTotals;

    topPlayerTotals = addPlaceToTotals(topPlayerTotals)

    if (topPlayerTotals.length == 0) {
      return;
    } else {
      return (
        <div className='league-player-display'>
          <p className="league-title-standard-small text-center"><b>{ statLabel[stat] }</b></p>
          <div className='league-player-container'>
            <div className='league-game-player-stats'>
              { topPlayerTotals.map((player) => showTotalStatRow(player, stat) ) }
            </div>
          </div>
        </div>
      )
    }
  }

  function addPlaceToTotals(topPlayerTotals) {
    for(let i = 0; i < topPlayerTotals.length; i++) {
      let p = topPlayerTotals[i]
      let place = (i + 1).toString()

      if (i == 0) {
        let pa = topPlayerTotals[i+1]

        if (p.total == pa.total) {
          p.place = `T${place}`
        } else {
          p.place = place
        }
      } else if (i == topPlayerTotals.length - 1) {
        let pb = topPlayerTotals[i-1]

        if (p.total == pb.total) {
          p.place = pb.place
        } else {
          p.place = place
        }
      } else {
        let pb = topPlayerTotals[i-1]
        let pa = topPlayerTotals[i+1]

        if (p.total == pb.total) {
          p.place = pb.place
        } else if (p.total == pa.total) {
          p.place = `T${place}`
        } else {
          p.place = place
        }
      }
    }

    return topPlayerTotals;
  }

  if (dataReady == false) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <div className='league-records'>
              <div className="league-game-stats-container">
                  <p className="league-title-skinny-medium"><b>All-Time Leaders</b></p>
                  { showTopStats('points') }
                  { showTopStats('rebounds') }
                  { showTopStats('assists') }
                  { showTopStats('blocks') }
                  { showTopStats('steals') }
                </div>
              </div>
            </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default Records;
